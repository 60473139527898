import { PublicConfigurations } from "@types";
import { replaceContentStackURL } from "@utils";
import { formatImageToPicture } from "utils/format";
import {
  CSFlexibleFailResultPage,
  CSFlexibleForm,
  CSFlexibleFormBlocks,
  CSFlexibleFormSettings,
  CSFlexibleOTPVerificationScreen,
  CSFlexibleSuccessResultPage,
  CSFormBlock,
  CSFormPageBlockType,
} from "../types/contentstack.types";
import {
  FlexibleFormBlocks,
  NextGenFlexibleFormApi,
  NextGenFlexibleFormOTPVerificationScreen,
  NextGenFlexibleFormParams,
  NextGenFlexibleFormProps,
  NextGenFlexibleFormResultPage,
} from "../types/flexibleform.types";
import { handleEncryptWithKey, isFormField } from "../utils";
import {
  transformButtonBlock,
  transformIntroductionBlock,
  transformSectionTitleBlock,
  transformTextBlock,
  transformUSPBlock,
} from "./transformBlock";
import { resetApiNameSet, transformFormFields } from "./transformFields";

interface ExternActions {
  onFormSubmit?: (formObject: any, api: any) => void;
  onFormFail?: () => void;
  onFormSuccess?: () => void;
  onFormClose?: () => void;
  onVerifyOTP?: () => void;
  customApiSubmitForm?: (formObject: any, api: any) => any;
  validateOnSubmit?: boolean;
  validateOnBlur?: boolean;
  strictValidate?: boolean;
}

export function transformCSFlexibleForm(
  flexibleFormProps: CSFlexibleForm,
  externActions: ExternActions = {},
  publicConfigurations: PublicConfigurations,
  fieldVariant: "outlined" | "standard" = "outlined"
): NextGenFlexibleFormProps {
  const {
    form_screen,
    error_screen,
    thank_you_screen,
    form_settings,
    otp_verification_screen,
  } = flexibleFormProps;

  resetApiNameSet();

  const formBlocks = transformFormBlocks(form_screen, fieldVariant);

  return {
    pages: {
      form: formBlocks,
      success: transformSuccessResultPage(thank_you_screen),
      failure: transformFailResultPage(error_screen),
      otp: otp_verification_screen?.enabled
        ? transformOTPVerificationScreen(otp_verification_screen)
        : null,
    },
    style: transformStyle(form_settings),
    params: transformFormParams(form_settings, externActions),
    api: {
      ...transformFormApi(form_settings, publicConfigurations),
      customApiSubmitForm: externActions?.customApiSubmitForm,
    },
  };
}

function transformStyle(settings: CSFlexibleFormSettings) {
  const { background_color, background_desktop, background_mobile } = settings;

  const hasBackground = !!(background_desktop || background_mobile);

  return {
    backgroundColor: background_color || "#FFF",
    backgroundImage: hasBackground
      ? formatImageToPicture(
          background_desktop?.url ?? "",
          background_mobile?.url
        ).media
      : null,
  };
}

function transformFormApi(
  settings: CSFlexibleFormSettings,
  publicConfigurations: PublicConfigurations
): NextGenFlexibleFormApi {
  const {
    api_request_body,
    api_request_header,
    api_url,
    submission_endpoint,
    api_error_message,
  } = settings;

  return {
    apiSubmissionEndpoint: submission_endpoint,
    apiUrl: publicConfigurations?.flexibleFormApiUrl ?? "", //api_url,
    apiRequestHeader: {
      ...api_request_header,
      "x-api-key": publicConfigurations?.flexibleFormApiKey ?? "",
    },
    apiRequestBody: api_request_body,
    apiErrorMessage:
      api_error_message ||
      "Sorry, our systems are experiencing some difficulties. Please submit again shortly.",
  };
}

function transformFormParams(
  settings: CSFlexibleFormSettings,
  externActions: ExternActions
): NextGenFlexibleFormParams {
  return {
    isPopup: settings.display_close_icon ?? false,
    companyLogo: formatImageToPicture(settings.company_logo?.url ?? "").media,

    onClosePopup: externActions.onFormClose,
    onFormSubmit: externActions.onFormSubmit,
    onFormFail: externActions.onFormFail,
    onFormSuccess: externActions.onFormSuccess,
    onVerifyOTP: externActions.onVerifyOTP,
    onEncryptWithKey: handleEncryptWithKey,
    validateOnSubmit: externActions.validateOnSubmit,
    validateOnBlur: externActions.validateOnBlur,
    strictValidate: externActions.strictValidate,
  };
}

function transformSuccessResultPage(
  pageParams: CSFlexibleSuccessResultPage
): NextGenFlexibleFormResultPage {
  const {
    title,
    description,
    image,
    background_desktop,
    background_mobile,
    background_color,
  } = pageParams;
  const hasBackground = !!(background_desktop || background_mobile);

  return {
    title,
    description,
    picture: formatImageToPicture(replaceContentStackURL(image?.url) ?? "")
      .media,
    backgroundColor: background_color ?? "#FFF",
    backgroundImage: hasBackground
      ? formatImageToPicture(
          background_desktop?.url ?? "",
          background_mobile?.url
        ).media
      : undefined,
  };
}

function transformFailResultPage(
  pageParams: CSFlexibleFailResultPage
): NextGenFlexibleFormResultPage | null {
  const {
    title,
    description,
    image,
    background_desktop,
    background_mobile,
    background_color,
    button_label,
    button_link,
  } = pageParams;

  // If there is no title and description, we don't need to show the page
  // An error message will be shown on the form page
  if (!title && !description) {
    return null;
  }
  const hasBackground = !!(background_desktop || background_mobile);

  return {
    title,
    description,
    picture: formatImageToPicture(image?.url ?? "").media,
    backgroundColor: background_color ?? "#FFF",
    backgroundImage: hasBackground
      ? formatImageToPicture(
          background_desktop?.url ?? "",
          background_mobile?.url
        ).media
      : undefined,
    buttonLabel: button_label,
    buttonLink: button_link,
  };
}

function transformOTPVerificationScreen(
  pageParams: CSFlexibleOTPVerificationScreen
): NextGenFlexibleFormOTPVerificationScreen | null {
  const {
    title,
    description,
    subtitle,
    enabled,
    length_of_otp,
    resend_text,
    resend_button,
    success_screen,
  } = pageParams;

  if (!enabled) {
    return null;
  }

  return {
    title,
    description,
    subtitle,
    enabled,
    lengthOfOTP: length_of_otp,
    resendText: resend_text,
    resendButton: resend_button,
    successScreen: {
      image: {
        url: success_screen?.image?.url || "",
      },
      title: success_screen?.title || "",
      description: success_screen?.description || "",
      button: {
        gaLabel: success_screen?.button?.ga_label || "",
        link: {
          href: success_screen?.button?.link.href || "",
          title: success_screen?.button?.link.title || "",
        },
      },
    },
  };
}

function transformFormBlocks(
  blocks: Partial<CSFlexibleFormBlocks>[],
  fieldVariant: "outlined" | "standard" = "outlined"
) {
  const blocksArray: CSFormBlock[] = [];

  // Extract block type from the object
  if (blocks && blocks.length > 0) {
    blocks.forEach((block, i) => {
      const key = Object.keys(block)[0];
      if (key && block[key]) {
        blocksArray.push({
          ...block[key],
          type: key,
        });
      }
    });
  }

  const compBlocks: FlexibleFormBlocks[] = [];

  for (let i = 0; i < blocksArray.length; i++) {
    const blockContent = blocksArray[i];
    let tempBlock: null | FlexibleFormBlocks = null;

    if (!blockContent.type) continue;

    // Handle blocks
    if (blockContent.type === CSFormPageBlockType.IntroductionBlock) {
      tempBlock = transformIntroductionBlock(blockContent);
    }

    if (blockContent.type === CSFormPageBlockType.MarketingBlock) {
      tempBlock = transformUSPBlock(blockContent);
    }

    if (blockContent.type === CSFormPageBlockType.SectionTitle) {
      tempBlock = transformSectionTitleBlock(blockContent);
    }

    if (blockContent.type === CSFormPageBlockType.ButtonBlock) {
      tempBlock = transformButtonBlock(blockContent);
    }

    if (blockContent.type === CSFormPageBlockType.FreeText) {
      tempBlock = transformTextBlock(blockContent);
    }

    // Handle fields
    if (isFormField(blockContent.type)) {
      const fields = [blockContent];
      for (let j = i + 1; j < blocksArray.length; j++) {
        const blockContent = blocksArray[j];

        if (!isFormField(blockContent.type)) {
          break;
        }

        fields.push(blockContent);
        i++;
      }

      tempBlock = transformFormFields(fields, fieldVariant);
    }

    if (tempBlock) compBlocks.push(tempBlock);
  }

  return compBlocks;
}
