// Nextgen Lib Props
import {
  FlexibleFormApiFormattingType,
  FlexibleFormFormattingType,
  FlexibleFormValidationType,
} from "./common.type";
import { NextGenPicture } from "./nextGen.types";

export interface NextGenFlexibleFormProps {
  pages: {
    form: FlexibleFormBlocks[];
    otp: NextGenFlexibleFormOTPVerificationScreen | null;
    success: NextGenFlexibleFormResultPage;
    failure: NextGenFlexibleFormResultPage | null;
  };
  style: NextGenFlexibleFormStyle;
  params: NextGenFlexibleFormParams;
  api: NextGenFlexibleFormApi;
}

export interface NextGenFlexibleFormResultPage {
  title: string;
  description: string;
  picture: NextGenPicture;
  backgroundColor: string;
  backgroundImage?: NextGenPicture;
  buttonLabel?: string;
  buttonLink?: string;
}

interface NextGenFlexibleFormStyle {
  backgroundColor: string;
  backgroundImage: null | NextGenPicture;
}

export interface NextGenFlexibleFormOTPVerificationScreen {
  title: string;
  description: string;
  subtitle: string;
  enabled: boolean;
  lengthOfOTP: number;
  resendText: string;
  resendButton: string;
  successScreen: {
    image: {
      url: string;
    };
    title: string;
    description: string;
    button: {
      gaLabel: string;
      link: {
          href: string;
          title: string;
      }
    }
  }
}

export interface NextGenFlexibleFormParams {
  isPopup: boolean;
  onClosePopup?: () => void;
  onFormSubmit?: (formObject: any, requestHeader: any) => void;
  onFormFail?: () => void;
  onFormSuccess?: () => void;
  onVerifyOTP?: () => void;
  onEncryptWithKey?: (plaintext: string, key: string) => void;
  companyLogo?: NextGenPicture;
  validateOnSubmit?: boolean;
  validateOnBlur?: boolean;
  strictValidate?: boolean;
}

export interface NextGenFlexibleFormApi {
  apiSubmissionEndpoint: string;
  apiUrl: string;
  apiRequestHeader: Object;
  apiRequestBody: Object;
  apiErrorMessage: string;
  customApiSubmitForm?: (formObject: any, api: any) => any;
}

export enum FlexibleFormBlockType {
  IntroductionBlock = "header_block",
  USPBlock = "usp_block",
  SectionTitle = "form_title_block",
  ButtonBlock = "submit_button",
  FreeTextBlock = "free_text_block",
  FormFields = "form_fields",
}

export type FlexibleFormBlocks =
  | FlexibleFormIntroductionBlock
  | FlexibleFormUSPBlock
  | FlexibleFormSectionTitleBlock
  | FlexibleFButtomBlock
  | FlexibleFormFieldsBlock
  | FlexibleFormFreeTextBlock;

export interface FlexibleFormIntroductionBlock {
  type: FlexibleFormBlockType.IntroductionBlock;
  title: string;
  picture: null | NextGenPicture;
  style: Object;
}

export interface FlexibleFormUSPBlock {
  type: FlexibleFormBlockType.USPBlock;
  title: string;
  subtitle: string;
  image: null | NextGenPicture;
  items: {
    picture: null | NextGenPicture;
    title: string;
    text: string;
  }[];
  style: Object;
}

export interface FlexibleFormSectionTitleBlock {
  type: FlexibleFormBlockType.SectionTitle;
  picture: null | NextGenPicture;
  title: string;
  style: Object;
}

export interface FlexibleFButtomBlock {
  type: FlexibleFormBlockType.ButtonBlock;
  title: string;
  style: Object;
  icon: string;
}

export enum FieldType {
  TEXT = "text",
  SELECT = "select",
  PASSWORD = "password",
  CHECKBOX = "checkbox",
  CONDITIONAL_CHECKBOX = "conditional_checkbox",
  EMAIL = "email",
  CONTACT_NUMBER = "contact_number",
  LIST = "list",
  DATEPICKER = "datepicker",
}

export interface FlexibleFormFieldsBlock {
  type: FlexibleFormBlockType.FormFields;
  fields: FlexibleFormFields[];
  style: {};
}

export interface FlexibleFormFreeTextBlock {
  type: FlexibleFormBlockType.FreeTextBlock;
  text: string;
  style: Object;
}

export type FlexibleFormFields = (
  | FlexibleFormTextField
  | FlexibleFormSelectField
  | FlexibleFormPasswordField
  | FlexibleFormCheckboxField
  | FlexibleFormEmailField
  | FlexibleFormContactNumberField
  | FlexibleFormListField
  | FlexibleFormDatePickerField
) & {
  fieldVariant: "outlined" | "standard";
};

export interface FlexibleFormTextField {
  fieldType: FieldType.TEXT;
  fieldName: string;
  isRequired: boolean;
  isVisible: boolean;
  isReadOnly: boolean;
  isPII: boolean;
  isEncrypted: boolean;
  defaultValue: string;
  placeholder: string;
  apiName: string;
  validationScript: () => void;
  validationOptions?: FlexibleFormFieldValidationOption[];
  formattingOptions: FlexibleFormFieldFormattingOption[];
  apiFormattingOptions?: FlexibleFormFieldApiFormattingOption[];
  validationRules: {
    [key: string]: FlexibleFormValidationRule;
  };
  includeInApi: boolean;
}

export interface FlexibleFormSelectField {
  fieldType: FieldType.SELECT;
  fieldName: string;
  isVisible: boolean;
  isRequired: boolean;
  isReadOnly: boolean;
  isPII: boolean;
  isEncrypted: boolean;
  placeholder: string;
  defaultValue:
    | {
        text: string;
        value: string;
      }
    | {};
  apiName: string;
  availableValues: {
    text: string;
    value: string;
  }[];
  validationOptions?: FlexibleFormFieldValidationOption[];
  apiFormattingOptions?: FlexibleFormFieldApiFormattingOption[];
  validationRules: {
    [key: string]: FlexibleFormValidationRule;
  };
  includeInApi: boolean;
}

export interface FlexibleFormPasswordField {
  fieldType: FieldType.PASSWORD;
  fieldName: string;
  isVisible: boolean;
  isRequired: boolean;
  isReadOnly: boolean;
  isPII: boolean;
  placeholder: string;
  defaultValue: string;
  apiName: string;
  validationOptions?: FlexibleFormFieldValidationOption[];
  formattingOptions: FlexibleFormFieldFormattingOption[];
  apiFormattingOptions?: FlexibleFormFieldApiFormattingOption[];

  mustContainsLowerChars: boolean;
  mustContainsNumbers: boolean;
  mustContainsSpecialChars: boolean;
  mustContainsUpperChars: boolean;
  mustMatch: [];
  minLength: number;
  maxLength: number;
  includeInApi: boolean;
}

export interface FlexibleFormCheckboxField {
  fieldType: FieldType.CHECKBOX;
  apiName: string;
  defaultValue: boolean;
  fieldName: string;
  isRequired: boolean;
  isVisible: boolean;
  isPII: boolean;
  text: string;
  validationOptions?: FlexibleFormFieldValidationOption[];
  includeInApi: boolean;
}

export interface FlexibleFormConditionalCheckboxField {
  fieldType: FieldType.CONDITIONAL_CHECKBOX;
  apiName: string;
  defaultValue: boolean;
  fieldName: string;
  isRequired: boolean;
  isVisible: boolean;
  isPII: boolean;
  conditionalTexts: {
    fieldApiName: string;
    fieldApiValue: string;
    text: string;
    isDefault: boolean;
  }[];
  validationOptions?: FlexibleFormFieldValidationOption[];
  includeInApi: boolean;
}

export interface FlexibleFormEmailField {
  fieldType: FieldType.EMAIL;
  fieldName: string;
  isVisible: boolean;
  isRequired: boolean;
  isReadOnly: boolean;
  isPII: boolean;
  defaultValue: string;
  apiName: string;
  placeholder: string;
  validationOptions?: FlexibleFormFieldValidationOption[];
  formattingOptions: FlexibleFormFieldFormattingOption[];
  apiFormattingOptions?: FlexibleFormFieldApiFormattingOption[];
  validationRules: {
    [key: string]: FlexibleFormValidationRule;
  };
  includeInApi: boolean;
}

export interface FlexibleFormContactNumberField {
  fieldType: FieldType.CONTACT_NUMBER;
  fieldName: string;
  isVisible: boolean;
  isRequired: boolean;
  isReadOnly: boolean;
  isPII: boolean;
  isEncrypted: boolean;
  defaultValue: string;
  apiName: string;
  country?: string;
  placeholder: string;
  validationOptions?: FlexibleFormFieldValidationOption[];
  formattingOptions: FlexibleFormFieldFormattingOption[];
  apiFormattingOptions?: FlexibleFormFieldApiFormattingOption[];
  validationRules: {
    [key: string]: FlexibleFormValidationRule;
  };
  includeInApi: boolean;
}

export interface FlexibleFormListField {
  fieldType: FieldType.LIST;
  fieldName: string;
  isVisible: boolean;
  isRequired: boolean;
  isReadOnly: boolean;
  isPII: boolean;
  placeholder: string;
  defaultValue: {
    text: string;
    value: string;
  }[];
  apiName: string;
  availableValues: {
    text: string;
    value: string;
  }[];
  validationOptions?: FlexibleFormFieldValidationOption[];
  validationRules: {
    [key: string]: FlexibleFormValidationRule;
  };
  includeInApi: boolean;
  multipleChoice: boolean;
}

export interface FlexibleFormDatePickerField {
  fieldType: FieldType.DATEPICKER;
  fieldName: string;
  isRequired: boolean;
  isVisible: boolean;
  isReadOnly: boolean;
  isPII: boolean;
  defaultValue: string;
  placeholder: string;
  apiName: string;
  validationOptions?: FlexibleFormFieldValidationOption[];
  apiFormattingOptions?: FlexibleFormFieldApiFormattingOption[];
  validationScript: () => void;
  validationRules: {
    [key: string]: FlexibleFormValidationRule;
  };

  // Date picker props
  minAge?: number;
  maxAge?: number;
  minDate?: string;
  maxDate?: string;
  allowDateInFuture?: boolean;

  dateFormatInput: string;
  dateFormattingProps: Object;

  includeInApi: boolean;
}

interface FlexibleFormValidationRule {
  value?: string | number | Array<any> | Object;
  errorMessage?: string;
}

export interface FlexibleFormFieldValidationOption {
  type: FlexibleFormValidationType;
  params?: any; //
  errorMessage?: string;
}

export interface FlexibleFormFieldFormattingOption {
  type: FlexibleFormFormattingType;
  params?: any; //
}

export interface FlexibleFormFieldApiFormattingOption {
  type: FlexibleFormApiFormattingType;
  params?: any; //
}
