import { useContext, useMemo } from "react";
import {
  FieldType,
  FlexibleFormBlockType,
  FlexibleFormFields,
  NextGenFlexibleFormProps,
} from "./types/flexibleform.types";
import get from "lodash/get";
import set from "lodash/set";
import uniq from "lodash/uniq";
import { FlexibleForm as NextGenFlexibleForm } from "@fwd-dep/nextgen-ui-lib";
import { transformCSFlexibleForm } from "./transform/transform";
import { CSFlexibleForm } from "./types/contentstack.types";
import { EnquiryLeadForm } from "shared-components/EnquiryLeadForm";
import { PublicConfigurations } from "@types";
import AppContext from "context/AppContext";

interface ParamUpdater {
  target?: string;
  params: string[];
  setReadOnly?: boolean;
}

/**
 * Update field with value from the URL
 * @param field
 * @param defaultValues
 * @param setReadOnly
 * @returns The updated field or null
 */
function updateField(
  field: FlexibleFormFields,
  defaultValues: string[],
  setReadOnly = false
) {
  if (!defaultValues || defaultValues.length === 0) return field;

  if (
    field.fieldType === FieldType.TEXT ||
    field.fieldType === FieldType.EMAIL ||
    field.fieldType === FieldType.PASSWORD
  ) {
    // Set the value
    const defaultValue = defaultValues[0];
    return { ...field, defaultValue, isReadOnly: setReadOnly };
  }

  if (field.fieldType === FieldType.SELECT) {
    // Check if the value is available in the options
    const defaultValue = defaultValues[0];
    const objValue = field.availableValues.find(
      (a) => a.value === defaultValue
    );

    if (objValue) {
      return { ...field, defaultValue: objValue, isReadOnly: setReadOnly };
    }
  }

  if (field.fieldType === FieldType.LIST) {
    // Check if multiple values and assign them if avaialble
    const arrValues = defaultValues
      .map((v) => field.availableValues.find((a) => a.value === v))
      .filter((v) => !!v);
    if (arrValues.length > 0) {
      const existingDefaultValues = field.defaultValue ?? [];
      return {
        ...field,
        defaultValue: [...arrValues, ...existingDefaultValues],
        isReadOnly: setReadOnly,
      };
    }
  }

  if (field.fieldType === FieldType.DATEPICKER) {
    // Check if the date is valid
    const defaultValue = defaultValues[0];
    return { ...field, defaultValue, isReadOnly: setReadOnly };
  }

  if (field.fieldType === FieldType.CHECKBOX) {
    const defaultValue = defaultValues[0] === "true";
    return { ...field, defaultValue, isReadOnly: setReadOnly };
  }

  return null;
}

/**
 * 1) Get the parameters from the URL
 * 2) For each param object from CS, get the parameters from the URL
 * 3) If no target, add the parameters to the request body
 * 4) If target, update the field with the parameters
 * @param formProps
 * @param paramsUpdater
 * @returns
 */
function setDefaultFormValues(
  formProps: NextGenFlexibleFormProps,
  paramsUpdater: ParamUpdater[]
): NextGenFlexibleFormProps {
  const allParams = new URLSearchParams(window.location.search);

  let requestBodyAdditionnalProps = {};

  paramsUpdater.forEach(({ target, params, setReadOnly }) => {
    // Find params values
    const objParams = getParam(allParams, params);

    // If target is empty add parameters to the request body
    if (!target) {
      requestBodyAdditionnalProps = {
        ...requestBodyAdditionnalProps,
        ...objParams,
      };
      return;
    }

    // Update field
    formProps.pages.form.forEach((block, iBlock) => {
      if (block.type !== FlexibleFormBlockType.FormFields) return;

      block.fields.forEach((field, iField) => {
        if (field.apiName === target) {
          const valuesArr: string[] = Object.values(objParams);
          // Split values if multiple values separated by a comma
          const values = valuesArr.map((v: string) => v.split(",")).flat();
          // Keep unique values, to avoid duplicates
          const uniqueValues = uniq(values); //Array.from(new Set(values));

          const fieldUpdated = updateField(field, uniqueValues, setReadOnly);
          if (fieldUpdated) {
            set(
              formProps,
              `pages.form[${iBlock}].fields[${iField}]`,
              fieldUpdated
            );
          }
        }
      });
    });
  });

  // Get default Body
  const currentBody = get(formProps, "api.apiRequestBody", {});
  // Get the path where the payload mst be inserted
  const bodyPath = get(currentBody, "path", "");

  // Get the default body paylod
  const defaultBodyContent = bodyPath ? get(currentBody, bodyPath, {}) : {};

  // Update the payload by injecting the values found from the URL params
  set(formProps, `api.apiRequestBody${bodyPath ? `.${bodyPath}` : ""}`, {
    ...defaultBodyContent,
    ...requestBodyAdditionnalProps,
  });

  return formProps;
}

/**
 * Find parameter values from the URL
 * @param paramsObj
 * @param paramsList
 * @returns
 */
function getParam(paramsObj: URLSearchParams, paramsList: string[]) {
  const obj = {};
  paramsList.forEach((param) => {
    if (paramsObj.get(param)) obj[param] = paramsObj.get(param);
  });

  return obj;
}

export function getUrlParams(
  formProps: NextGenFlexibleFormProps,
  paramsConfig: ParamUpdater[]
) {
  try {
    if (!window) return formProps;
  } catch (error) {
    return formProps;
  }

  return setDefaultFormValues(formProps, paramsConfig);
}

interface CompProps {
  data: {
    reference: CSFlexibleForm[];
    headerVisible: boolean;
    index: number;
    pageUid: string;
    customApiSubmitForm?: (formObject: any, api: any) => void;
    validateOnSubmit: boolean;
    validateOnBlur: boolean;
    strictValidate: boolean;
    publicConfigurations: PublicConfigurations;
    fieldVariant?: "outlined" | "standard";
    onCustomFormSuccess?: () => void;
  };
}

const FlexiForm = ({ data }: CompProps) => {
  const appContext = useContext(AppContext);
  const formProps = useMemo(() => {
    const flexiForms = data.reference;
    if (!flexiForms || flexiForms.length < 1) return null;

    const formCsProps = flexiForms[0];
    let externalFunc: any = {
      customApiSubmitForm: data.customApiSubmitForm,
      validateOnSubmit: data.validateOnSubmit,
      validateOnBlur: data.validateOnBlur,
      strictValidate: data.strictValidate,
    };
    if (typeof data.onCustomFormSuccess === "function") {
      externalFunc.onFormSuccess = data.onCustomFormSuccess;
    }

    // Must format data from CS format to library format
    let transformedForm = transformCSFlexibleForm(
      formCsProps,
      externalFunc,
      Object.keys(appContext.publicConfigurations).length > 0
        ? appContext.publicConfigurations
        : data.publicConfigurations,
      data.fieldVariant
    );

    // Update form values and fields props based on URL params
    transformedForm = getUrlParams(
      transformedForm,
      formCsProps.form_settings.url_query_string
    );

    return transformedForm;
  }, [data]);

  if (!formProps) return <></>;

  return <NextGenFlexibleForm {...formProps} />;
};

const LEAD_FORM_TYPES = ["lead_form", "lead_form_product"];
export const CSFlexiForm = (props: CompProps) => {
  const { data } = props;

  if (LEAD_FORM_TYPES.includes(data?.reference?.[0].display_setting)) {
    return <EnquiryLeadForm data={data} />;
  }

  return <FlexiForm data={data} />;
};
