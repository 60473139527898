export enum FlexibleFormValidationType {
  VALIDATE_MIN_AGE = "validation_min_age",
  VALIDATE_MAX_AGE = "validation_max_age",
  VALIDATE_MIN_DATE = "validation_min_date",
  VALIDATE_MAX_DATE = "validation_max_date",
  VALIDATE_REGEX = "validation_regex",
  VALIDATE_DATE_FORMAT = "validation_date_format",
  VALIDATE_DATE_ALLOW_FUTURE = "validate_allow_future",
  VALIDATE_TEXT_MAX_LENGTH = "validation_max_length",
  VALIDATE_REQUIRED = "validation_required_field",
  VALIDATE_NUMBER_MIN = "validation_number_min",
  VALIDATE_NUMBER_MAX = "validation_number_max",
}

export enum FlexibleFormFormattingType {
    FORMAT_VALUE = 'formatting_value',
}

export enum FlexibleFormApiFormattingType {
    FORMAT_API_DATE = 'formatting_api_date',
    FORMAT_API_REPLACE_VALUE = 'formatting_api_replace_value',
    FORMAT_API_DATE_TO_TIMESTAMP = "formatting_api_date_to_timestamp"
}