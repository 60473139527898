import { replaceContentStackURL } from "@utils";
import { formatImageToPicture } from "utils/format";
import {
  CSFormButtonBlock,
  CSFormFreeTextBlock,
  CSFormIntroductionBlock,
  CSFormMarketingBlockBlock,
  CSFormSectionTitle,
} from "../types/contentstack.types";
import {
  FlexibleFButtomBlock,
  FlexibleFormBlockType,
  FlexibleFormFreeTextBlock,
  FlexibleFormIntroductionBlock,
  FlexibleFormSectionTitleBlock,
  FlexibleFormUSPBlock,
} from "../types/flexibleform.types";

const allowedStyleProps = [
  "margin",
  "marginTop",
  "marginBottom",
  "margin-bottom",
  "margin-top",
];

function transformStyle(originStyle: Object) {
  const outputStyle = {};
  for (let styleProp of allowedStyleProps) {
    if (originStyle[styleProp] !== undefined) {
      outputStyle[styleProp] = originStyle[styleProp];
    }
  }

  return outputStyle;
}

export function transformIntroductionBlock(
  block: CSFormIntroductionBlock
): FlexibleFormIntroductionBlock {
  const picSrc = replaceContentStackURL(block.icon?.url);
  return {
    type: FlexibleFormBlockType.IntroductionBlock,
    title: block.heading,
    picture: picSrc ? formatImageToPicture(picSrc).media : null,
    style: transformStyle(block.custom_styling ?? {}),
  };
}

export function transformUSPBlock(
  block: CSFormMarketingBlockBlock
): FlexibleFormUSPBlock {
  const picSrc = replaceContentStackURL(block.banner_image?.url);

  return {
    type: FlexibleFormBlockType.USPBlock,
    title: block.title,
    subtitle: block.description,
    image: picSrc ? formatImageToPicture(picSrc).media : null,
    items: block.usp.map((item) => {
      const itemPic = item.usp_icon?.url;
      return {
        picture: itemPic ? formatImageToPicture(itemPic).media : null,
        title: item.usp_title,
        text: item.usp_description,
      };
    }),
    style: transformStyle(block.custom_styling ?? {}),
  };
}

export function transformSectionTitleBlock(
  block: CSFormSectionTitle
): FlexibleFormSectionTitleBlock {
  const picSrc = replaceContentStackURL(block.icon?.url);
  return {
    type: FlexibleFormBlockType.SectionTitle,
    picture: picSrc ? formatImageToPicture(picSrc).media : null, //block.icon,
    title: block.title,
    style: {}, // transformStyle(block. ?? {})
  };
}

export function transformButtonBlock(
  block: CSFormButtonBlock
): FlexibleFButtomBlock {
  return {
    type: FlexibleFormBlockType.ButtonBlock,
    title: block.button_label,
    icon: block.icon,
    style: transformStyle(block.style ?? {}),
  };
}

export function transformTextBlock(
  block: CSFormFreeTextBlock
): FlexibleFormFreeTextBlock {
  const { design_settings = { top_margin: "", bottom_margin: "" } } = block;
  const { top_margin, bottom_margin } = design_settings;

  return {
    type: FlexibleFormBlockType.FreeTextBlock,
    text: block.text_block,
    style: transformStyle(
      block.style ?? {
        marginTop:
          top_margin && !Number.isNaN(top_margin) ? `${top_margin}px` : "16px",
        marginBottom:
          bottom_margin && !Number.isNaN(bottom_margin)
            ? `${bottom_margin}px`
            : "16px",
      }
    ),
  };
}
