import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import {
  FlexibleFormApiFormattingType,
  FlexibleFormFormattingType,
  FlexibleFormValidationType,
} from "../types/common.type";
import {
  CSFormBlock,
  CSFormConditionalConsentField,
  CSFormConsentField,
  CSFormDatePickerField,
  CSFormListField,
  CSFormPageBlockType,
  CSFormPageFieldType,
  CSFormPhoneNumberField,
  CSFormSelectField,
  CSFormTextField,
  CSValidationRules,
} from "../types/contentstack.types";
import {
  FieldType,
  FlexibleFormBlockType,
  FlexibleFormCheckboxField,
  FlexibleFormConditionalCheckboxField,
  FlexibleFormContactNumberField,
  FlexibleFormDatePickerField,
  FlexibleFormFieldApiFormattingOption,
  FlexibleFormFieldFormattingOption,
  FlexibleFormFields,
  FlexibleFormFieldsBlock,
  FlexibleFormFieldValidationOption,
  FlexibleFormListField,
  FlexibleFormSelectField,
  FlexibleFormTextField,
} from "../types/flexibleform.types";
import { isFormField } from "../utils";

const apiNames = new Set<string>();

export function resetApiNameSet() {
  apiNames.clear();
}

const defineApiName = () => {
  return (fieldType: FieldType) => {
    let apiName = fieldType.toLowerCase();

    if (apiNames.has(apiName)) {
      apiName = apiName + "_" + apiNames.size;
    }

    apiNames.add(apiName);

    return apiName;
  };
};

const setApiName = defineApiName();

function transformValidationRules(
  rules?: CSValidationRules
): FlexibleFormFieldValidationOption[] {
  let validationOptions: FlexibleFormFieldValidationOption[] = [];

  if (!rules) return [];

  Object.entries(rules).forEach(([ruleName, ruleObj]) => {
    const validationRuleName = ruleName as FlexibleFormValidationType;
    if (
      validationRuleName &&
      Object.values(FlexibleFormValidationType).includes(validationRuleName)
    ) {
      let validationObj = { type: validationRuleName };
      if (ruleObj.value !== undefined) {
        if (ruleObj.value instanceof Array)
          validationObj["params"] = ruleObj.value;
        else validationObj["params"] = [ruleObj.value];
      }
      if (ruleObj.error_message)
        validationObj["errorMessage"] = ruleObj.error_message;

      validationOptions.push(validationObj);
    }
  });

  return validationOptions;
}

function transformFormattingRules(
  rules?: CSValidationRules
): FlexibleFormFieldFormattingOption[] {
  let formattingOptions: FlexibleFormFieldFormattingOption[] = [];

  if (!rules) return [];

  Object.entries(rules).forEach(([ruleName, ruleObj]) => {
    const validationRuleName = ruleName as FlexibleFormFormattingType;

    if (
      validationRuleName &&
      Object.values(FlexibleFormFormattingType).includes(validationRuleName)
    ) {
      let validationObj = { type: validationRuleName };
      if (ruleObj.value instanceof Array)
        validationObj["params"] = ruleObj.value;
      else validationObj["params"] = [ruleObj.value];

      formattingOptions.push(validationObj);
    }
  });

  return formattingOptions;
}

function transformApiFormattingRules(
  rules?: CSValidationRules
): FlexibleFormFieldApiFormattingOption[] {
  let apiFormattingOptions: FlexibleFormFieldApiFormattingOption[] = [];

  if (!rules) return [];

  Object.entries(rules).forEach(([ruleName, ruleObj]) => {
    const validationRuleName = ruleName as FlexibleFormApiFormattingType;

    if (
      validationRuleName &&
      Object.values(FlexibleFormApiFormattingType).includes(validationRuleName)
    ) {
      let validationObj = { type: validationRuleName };
      if (ruleObj.value !== undefined) {
        if (ruleObj.value instanceof Array)
          validationObj["params"] = ruleObj.value;
        else validationObj["params"] = [ruleObj.value];
      }

      apiFormattingOptions.push(validationObj);
    }
  });

  return apiFormattingOptions;
}

export function transformTextField(
  field: CSFormTextField
): FlexibleFormTextField {
  const includeInApi = !!field.api_parameter_name;
  const apiName = field.api_parameter_name ?? setApiName(FieldType.TEXT);

  return {
    fieldType: FieldType.TEXT,
    fieldName: "",
    isRequired: field.mandatory ?? false,
    isVisible: !field.hidden,
    isReadOnly: false,
    isEncrypted: field.encrypted ?? false,
    isPII: field.mask_pii_data_for_ga ?? false,
    defaultValue: field.default_text || "",
    placeholder: field.field_label ?? "",
    apiName,
    includeInApi,
    validationScript: () => {},
    validationOptions: transformValidationRules(field.validation_rules),
    formattingOptions: transformFormattingRules(field.validation_rules),
    apiFormattingOptions: transformApiFormattingRules(field.validation_rules),
    validationRules: field.validation_rules ?? {},
  };
}

export function transformSelectField(
  field: CSFormSelectField
): FlexibleFormSelectField {
  const includeInApi = !!field.api_parameter_name;
  const apiName = field.api_parameter_name ?? setApiName(FieldType.SELECT);

  const defaultOptions = field.dropdown.find((option) => option.default_value);

  let defaultValue = {};
  if (defaultOptions) {
    defaultValue = {
      text: defaultOptions.option_label,
      value: defaultOptions.option_api_parameter_name,
    };
  }

  return {
    fieldType: FieldType.SELECT,
    fieldName: "",
    isRequired: field.mandatory ?? false,
    isVisible: true,
    isReadOnly: false,
    isPII: field.mask_pii_data_for_ga ?? false,
    isEncrypted: field.encrypted ?? false,
    placeholder: field.field_label ?? "",
    defaultValue: defaultValue,
    apiName,
    includeInApi,
    availableValues: field.dropdown.map((option) => ({
      text: option.option_label,
      value: option.option_api_parameter_name,
    })),
    validationOptions: transformValidationRules(field.validation_rules),
    validationRules: field.validation_rules,
  };
}

export function transformListField(
  field: CSFormListField
): FlexibleFormListField {
  const includeInApi = !!field.api_parameter_name;
  const apiName = field.api_parameter_name ?? setApiName(FieldType.LIST);

  const availableValues = field.answers.map((option) => ({
    text: option.option_label,
    value: option.api_value,
  }));
  const hasOneAnswer = availableValues?.length === 1;
  const defaultValues = hasOneAnswer
    ? [availableValues[0]]
    : (field.default_value || []).map((option) => ({
        text: option.option_label,
        value: option.api_value,
      }));

  return {
    fieldType: FieldType.LIST,
    fieldName: "",
    isVisible: !hasOneAnswer,
    isRequired: field.mandatory,
    isReadOnly: false,
    isPII: field.mask_pii_data_for_ga ?? false,
    placeholder: field.field_label,
    defaultValue: defaultValues,
    apiName,
    includeInApi,
    availableValues,
    validationOptions: transformValidationRules(field.validation_rules),
    validationRules: field.validation_rules,
    multipleChoice: isNil(field.multiple_answers_allowed)
      ? true
      : field.multiple_answers_allowed,
  };
}

export function transformDatePickerField(
  field: CSFormDatePickerField
): FlexibleFormDatePickerField {
  const {
    minAge,
    maxAge,
    minDate,
    maxDate,
    futureDateAllowed,
    mandatory,
    placeholder,
    api_parameter_name,
    validation_rules,
  } = field;

  const includeInApi = !!api_parameter_name;
  const apiName = api_parameter_name ?? setApiName(FieldType.DATEPICKER);

  const additionnalDatePickerProps: Partial<FlexibleFormDatePickerField> = {};

  Object.entries(validation_rules ?? {}).forEach(([key, rule]) => {
    if (
      key === FlexibleFormValidationType.VALIDATE_MAX_AGE &&
      typeof rule.value === "number"
    ) {
      additionnalDatePickerProps["maxAge"] = rule.value;
    }
    if (
      key === FlexibleFormValidationType.VALIDATE_MIN_AGE &&
      typeof rule.value === "number"
    ) {
      additionnalDatePickerProps["minAge"] = rule.value;
    }
    if (
      key === FlexibleFormValidationType.VALIDATE_MIN_DATE &&
      typeof rule.value === "string"
    ) {
      additionnalDatePickerProps["minDate"] = rule.value;
    }
    if (
      key === FlexibleFormValidationType.VALIDATE_MAX_DATE &&
      typeof rule.value === "string"
    ) {
      additionnalDatePickerProps["maxDate"] = rule.value;
    }
    if (
      key === FlexibleFormValidationType.VALIDATE_DATE_FORMAT &&
      typeof rule.value === "string"
    ) {
      additionnalDatePickerProps["dateFormatInput"] = rule.value;
    }
    if (
      key === FlexibleFormValidationType.VALIDATE_DATE_ALLOW_FUTURE &&
      typeof rule.value === "boolean"
    ) {
      additionnalDatePickerProps["allowDateInFuture"] = rule.value;
    }
  });

  const fieldObject: FlexibleFormDatePickerField = {
    fieldType: FieldType.DATEPICKER,
    fieldName: "",
    isRequired: mandatory,
    isVisible: true,
    isReadOnly: false,
    isPII: field.mask_pii_data_for_ga ?? false,
    defaultValue: "",
    placeholder: placeholder,
    apiName,
    includeInApi,
    validationScript: () => {},
    dateFormatInput: "DD/MM/YYYY",
    dateFormattingProps: {
      mask: "XX/XX/XXXX",
      maskReplacer: "_",
      maskDivider: "/",
    },
    validationOptions: transformValidationRules(field.validation_rules),
    apiFormattingOptions: transformApiFormattingRules(field.validation_rules),
    validationRules: validation_rules ?? {},
    ...additionnalDatePickerProps,
  };

  return fieldObject;
}

function transformConsentField(
  field: CSFormConsentField
): FlexibleFormCheckboxField {
  const includeInApi = !!field.api_parameter_name;
  const apiName = field.api_parameter_name || setApiName(FieldType.CHECKBOX);

  return {
    fieldType: FieldType.CHECKBOX,
    apiName,
    includeInApi,
    defaultValue: field.pretick ?? false,
    fieldName: "",
    isRequired: true,
    isVisible: true,
    isPII: field.mask_pii_data_for_ga ?? false,
    text: field.content,
    validationOptions: transformValidationRules(field.validation_rules),
  };
}

function transformConditionalConsentField(
  field: CSFormConditionalConsentField
): FlexibleFormConditionalCheckboxField {
  const includeInApi = !!field.api_parameter_name;
  const apiName =
    field.api_parameter_name || setApiName(FieldType.CONDITIONAL_CHECKBOX);

  return {
    fieldType: FieldType.CONDITIONAL_CHECKBOX,
    apiName,
    includeInApi,
    defaultValue: field.pretick ?? false,
    fieldName: "",
    isRequired: true,
    isVisible: true,
    isPII: field.mask_pii_data_for_ga ?? false,
    conditionalTexts: field.conditions?.map((t) => ({
      fieldApiName: t.match_field_api_name,
      fieldApiValue: t.match_field_api_value,
      isDefault: !!t.default,
      text: t.content,
    })),
    validationOptions: transformValidationRules(field.validation_rules),
  };
}

function transformPhoneNumberField(
  field: CSFormPhoneNumberField
): FlexibleFormContactNumberField {
  const {
    field_label,
    mandatory,
    api_parameter_name,
    validation_rules,
    country_code,
  } = field;

  const includeInApi = !!api_parameter_name;
  const apiName = api_parameter_name ?? setApiName(FieldType.CONTACT_NUMBER);

  const countryCode = isEmpty(country_code)
    ? {}
    : {
        country: country_code.startsWith("+")
          ? country_code
          : `+${country_code}`,
      };

  return {
    fieldType: FieldType.CONTACT_NUMBER,
    fieldName: "",
    isVisible: true,
    isRequired: mandatory,
    isReadOnly: false,
    isPII: field.mask_pii_data_for_ga ?? false,
    isEncrypted: field.encrypted || country_code === "84" || false,
    defaultValue: "",
    apiName,
    includeInApi,
    placeholder: field_label,
    validationRules: validation_rules,
    validationOptions: transformValidationRules(field.validation_rules),
    formattingOptions: transformFormattingRules(field.validation_rules),
    apiFormattingOptions: transformApiFormattingRules(field.validation_rules),
    ...countryCode,
  };
}

/**
 * Transform a CS field to a NextGenLib Flexible Form field
 * @param field
 * @returns
 */
export function transformFormField(field: CSFormBlock) {
  if (!isFormField(field.type)) return null;

  switch (field.type) {
    case CSFormPageFieldType.TextField:
      return transformTextField(field);
    case CSFormPageFieldType.SelectField:
      return transformSelectField(field);
    case CSFormPageFieldType.ListField:
      return transformListField(field);
    case CSFormPageFieldType.PhoneNumberField:
      return transformPhoneNumberField(field);
    case CSFormPageFieldType.DatePickerField:
      return transformDatePickerField(field);
    case CSFormPageFieldType.ConsentField:
      return transformConsentField(field);
    case CSFormPageFieldType.ConditionalConsentField:
      return transformConditionalConsentField(field);
    default:
      return null;
  }
}

export function transformFormFields(
  fields: CSFormBlock[],
  fieldVariant: "outlined" | "standard"
): FlexibleFormFieldsBlock {
  const fieldsTransformed: any[] = [];

  fields.forEach((field) => {
    const fieldTransformed = transformFormField(field);
    if (fieldTransformed) {
      (fieldTransformed as any).fieldVariant = fieldVariant;
      fieldsTransformed.push(fieldTransformed);
    }
  });

  return {
    type: FlexibleFormBlockType.FormFields,
    fields: fieldsTransformed,
    style: {},
  };
}
